import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "cp-admin-title-tab__wrapper" }
const _hoisted_2 = { class: "cp-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tabs_nav = _resolveComponent("tabs-nav")!
  const _component_app_header_btn_add = _resolveComponent("app-header-btn-add")!
  const _component_app_col = _resolveComponent("app-col")!
  const _component_gui_loader = _resolveComponent("gui-loader")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_text_phone = _resolveComponent("text-phone")!
  const _component_app_table = _resolveComponent("app-table")!
  const _component_app_row = _resolveComponent("app-row")!

  return (_openBlock(), _createBlock(_component_app_row, {
    wrap: "nowrap",
    direction: "column",
    class: "cp-main-inner__wrapper cp-page__settings"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_app_col, { grow: 0 }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_tabs_nav, {
              items: _ctx.tabsNav,
              "active-id": _ctx.currentTab
            }, null, 8, ["items", "active-id"]),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_app_header_btn_add, { onClick: _ctx.toAdd }, null, 8, ["onClick"])
            ])
          ])
        ]),
        _: 1
      }),
      (Object.keys(_ctx.settings.table).length === 0)
        ? (_openBlock(), _createBlock(_component_app_col, {
            key: 0,
            grow: 1
          }, {
            default: _withCtx(() => [
              _createVNode(_component_gui_loader, { "absolute-center": "" })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (Object.keys(_ctx.settings.table).length)
        ? (_openBlock(), _createBlock(_component_app_table, {
            key: 1,
            class: "grow-1",
            headers: _ctx.settings.table.titles,
            items: _ctx.settings.table.rows,
            page: _ctx.settings.table.pagination.currentPage,
            "total-pages": _ctx.settings.table.pagination.totalPages,
            "total-items": _ctx.settings.table.totalItems,
            "page-items": _ctx.settings.pageAmountItems && +_ctx.settings.pageAmountItems,
            onChangePage: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$store.dispatch(_ctx.settings.actionPagination, $event))),
            onSelectAmount: _ctx.selectAmount,
            onSort: _ctx.sort
          }, {
            "item-name": _withCtx(({ value, item }) => [
              _createVNode(_component_router_link, {
                class: "app-table-link",
                to: { name: 'training_center_edit', params: { trainingCenterId: item.id } }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(value), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]),
            "item-phone": _withCtx(({ value }) => [
              value
                ? (_openBlock(), _createBlock(_component_text_phone, {
                    key: 0,
                    value: value
                  }, null, 8, ["value"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["headers", "items", "page", "total-pages", "total-items", "page-items", "onSelectAmount", "onSort"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}