
import { defineComponent } from 'vue';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import AppHeaderBtnAdd from '@/layouts/partials/AppHeaderBtnAdd.vue';
import AppTable from '@/components/ui/table/Table.vue';
import SettingsModule from '@/store/settings';
import TrainingCenterModule from '@/store/settings/trainingCenter';
import TextPhone from '@/components/table-items/TextPhone.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import { GuiLoader } from '@library/gigant_ui';

export default defineComponent({
  components: {
    GuiLoader,
    AppCol,
    AppRow,
    TextPhone,
    AppTable,
    AppHeaderBtnAdd,
    TabsNav,
  },
  computed: {
    tabsNav() {
      return SettingsModule.tabsNav;
    },
    currentTab() {
      return SettingsModule.allTabs.trainingCenter.id;
    },
    settings() {
      return TrainingCenterModule.pageSettings;
    },
  },
  methods: {
    toAdd() {
      this.$router.push({ name: 'training_center_add' });
    },
    selectAmount(value: string) {
      TrainingCenterModule.updatePageAmountItems(value);
    },
  },
  mounted() {
    SettingsModule.initTabsItems();
    TrainingCenterModule.init();
  },
});
